<template>
  <div class="form-group">
    <label v-if="name">{{ name }}</label>
    <b-form-input
      class="form-control form-control-lg form-control-solid"
      :type="type"
      v-model="input"
      :state="validateState()"
      aria-describedby="input-live-feedback"
      :placeholder="placeholder"
      :readonly="isReadOnly"
      :maxlength="hasLengthLimit ? maxLength : ''"
    />
    <b-form-invalid-feedback id="input-live-feedback">
      {{ errorMessage }}
    </b-form-invalid-feedback>
    <!-- This is a form text block (formerly known as help block) -->
    <b-form-text v-if="helpText">{{ helpText }}</b-form-text>
    <b-form-text v-if="hasLengthLimit"
      >{{ getInputCount() }} / {{ maxLength }}</b-form-text
    >
  </div>
</template>
<script>
export default {
  name: "ValidatedInput",
  props: {
    type: {
      String,
      default: "text"
    },
    name: String,
    value: [String, Number],
    v: {
      Type: Object,
      required: true
    },
    errorMessage: String,
    helpText: String,
    placeholder: String,
    isReadOnly: {
      type: Boolean,
      default: false
    },
    hasLengthLimit: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: String,
      default: "80"
    }
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.v.$touch();
        this.$emit("input", value);
      }
    }
  },
  methods: {
    validateState() {
      const { $dirty, $error } = this.v;
      return $dirty ? !$error : null;
    },
    getInputCount() {
      return this.value.length;
    }
  }
};
</script>
