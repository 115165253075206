<template>
  <!--begin::Content-->
  <div
    class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
  >
    <div class="d-flex flex-column-fluid flex-center">
      <!--begin::ResetPassword-->
      <div class="login-form">
        <!--begin::Form-->
        <form
          class="form"
          novalidate="novalidate"
          id="kt_login_forgot_form"
          @submit.stop.prevent="onSubmitResetPassword()"
        >
          <div class="pb-13 pt-lg-0 pt-5">
            <h3
              class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
            >
              Passwort zurücksetzen
            </h3>
            <p class="text-muted font-weight-bold font-size-h4">
              Geben Sie ihr neues Passwort ein
            </p>
          </div>
          <div class="form-group">
            <label>Password</label>
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="password"
              v-model="$v.form.new_password1.$model"
              :state="validateState('new_password1')"
              aria-describedby="input-live-feedback"
            />
            <password-meter
              :password="$v.form.new_password1.$model"
              @score="onPasswordScore"
            />
            <b-form-invalid-feedback id="input--live-feedback">
              {{ getPasswordError() }}
            </b-form-invalid-feedback>
            <b-form-text
              >Das Passwort muss mindestens 9 Zeichen lang sein und mindestens
              eine Zahl, einen Kleinbuchstaben und einen Großbuchstaben
              enthalten.</b-form-text
            >
          </div>
          <ValidatedInput
            name="Passwort bestätigen"
            v-model="form.new_password2"
            :v="$v.form.new_password2"
            type="password"
            errorMessage="Die Bestätigung muss mit ihrem Passwort übereinstimmen"
          />
          <div class="form-group d-flex flex-wrap pb-lg-0">
            <button
              type="submit"
              ref="kt_password_reset_submit"
              class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
            >
              Senden
            </button>
            <button
              type="button"
              class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
              @click="$router.push({ name: 'login' })"
            >
              Login
            </button>
          </div>
        </form>
      </div>
      <!--end::ResetPassword-->
    </div>
  </div>
  <!--end::Content-->
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import { authFormMixin } from "@/mixins/authFormMixin";
import { alertMixin } from "@/mixins/alertMixin";
import ValidatedInput from "@/components/ValidatedInput";
import passwordMeter from "vue-simple-password-meter";

import { mapState } from "vuex";
import { RESET_AUTH, RESET_PASSWORD_CONFIRM } from "@/store/auth.module";
import Swal from "sweetalert2";

function passwordScoreValidation() {
  return this.passwordScoreValidation();
}

async function isPasswordValidValidation() {
  return await this.isPasswordValid();
}

export default {
  components: { ValidatedInput, passwordMeter },
  mixins: [validationMixin, authFormMixin, alertMixin],
  name: "login",
  data() {
    return {
      form: {
        new_password1: "",
        new_password2: "",
        uid: this.$route.params.uid,
        token: this.$route.params.token
      }
    };
  },
  validations: {
    form: {
      new_password1: {
        required,
        passwordScoreValidation,
        isPasswordValidValidation
      },
      new_password2: {
        required,
        sameAsPassword: sameAs("new_password1")
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getPasswordError() {
      if (!this.$v.form.new_password1.passwordScoreValidation) {
        return this.passwordScoreError;
      }
      if (!this.$v.form.new_password1.isValidPasswordValidation) {
        return this.invalidPasswordError;
      }
    },
    onSubmitResetPassword() {
      // clear existing server errors
      this.$store.dispatch(RESET_AUTH);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_password_reset_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send reset password request
        this.$store
          .dispatch(RESET_PASSWORD_CONFIRM, this.form)
          // go to which page after successfully login
          .then(() => {
            Swal.fire({
              title: "",
              text: "Ihr Password wurde erfolgreich zurückgesetzt!",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-secondary"
              },
              willClose: () => {
                this.$router.push({ name: "login" });
              }
            });
          })
          .catch(() => {
            if (this.isInvalidToken()) {
              Swal.fire({
                title: "OOPS!",
                text:
                  "Ihr Reset-Link ist abgelaufen oder wurde bereits verwendet.",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-secondary"
                },
                heightAuto: false
              });
            } else {
              // console.log("Login error: ", error);
              this.$v.form.$touch();
              // console.error(this.errors);
              this.showCheckProvidedDataAlert();
            }
          });
        // .catch(() => {});
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    isPasswordValid() {
      if (this.errors === null) {
        return true;
      }
      if ("new_password2" in this.errors.response.data) {
        return false;
      }
      return true;
    },
    isInvalidToken() {
      if (this.errors === null) {
        return false;
      }
      if ("token" in this.errors.response.data) {
        return true;
      }
      return false;
    }
  }
};
</script>
